import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25RichTextEditorModule } from "../s25-rich-text-editor/s25.rich.text.editor.module";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { Rules } from "./s25.rule.const";
import { S25Util } from "../../util/s25-util";
import { UserprefService } from "../../services/userpref.service";
import { S25TimepickerModule } from "../s25-timepicker/s25.timepicker.module";
import ActionTarget = Rules.ActionTarget;

@Component({
    selector: "s25-ng-rule-target",
    template: `
        @if (init) {
            @switch (target.valueType.type) {
                @case ("contactRole") {
                    <div class="contactRole">
                        <s25-ng-multiselect-search-criteria
                            [modelBean]="{}"
                            [selectedItems]="rule.targets[target.valueType.action]"
                            [type]="'eventRoles'"
                            [popoverOnBody]="true"
                        ></s25-ng-multiselect-search-criteria>
                        @for (role of rule.targets[target.valueType.action]; track role; let i = $index) {
                            <div class="contactRoleRow">
                                <button (click)="rule.targets[target.valueType.action].splice(i, 1)" class="delete">
                                    <s25-ng-icon [type]="'close'" [label]="'Delete'"></s25-ng-icon>
                                </button>
                                <label>{{ role.itemName }}</label>
                                <div class="flexRow">
                                    <s25-ng-dropdown-search-criteria
                                        [type]="'contacts'"
                                        [(chosen)]="role.contact"
                                        [useChoiceForChosen]="true"
                                        [placeholder]="'Select Contact (optional)'"
                                    ></s25-ng-dropdown-search-criteria>
                                    <button
                                        class="aw-button aw-button--outline clearContact"
                                        (click)="role.contact = null"
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                }
                @case ("multiselectWithQuantity") {
                    <div class="quantity multiselectWithOption">
                        <s25-ng-multiselect-search-criteria
                            [modelBean]="{}"
                            [selectedItems]="rule.targets[target.valueType.action]"
                            [type]="target.valueType.criterion"
                            [customFilterValue]="target.valueType.filter"
                            [popoverOnBody]="true"
                            (changed)="updateQuantity($event)"
                        ></s25-ng-multiselect-search-criteria>
                        @if (!!rule.targets[target.valueType.action].length) {
                            <div class="header">Quantity</div>
                        }
                        @for (item of rule.targets[target.valueType.action]; track item; let i = $index) {
                            <div class="multiselectRow">
                                <button (click)="rule.targets[target.valueType.action].splice(i, 1)" class="delete">
                                    <s25-ng-icon [type]="'close'" [label]="'Delete'"></s25-ng-icon>
                                </button>
                                <div class="flexRow">
                                    <label>{{ item.itemName }}</label>
                                    <input
                                        type="number"
                                        [(ngModel)]="item.number"
                                        (ngModelChange)="item.itemValue = 'quantity=' + $event"
                                        [min]="1"
                                        class="c-input"
                                    />
                                </div>
                            </div>
                        }
                    </div>
                }
                @case ("multiselectWithCheckbox") {
                    <div class="multiselectWithOption checkbox">
                        <s25-ng-multiselect-search-criteria
                            [modelBean]="{}"
                            [selectedItems]="rule.targets[target.valueType.action]"
                            [type]="target.valueType.criterion"
                            [customFilterValue]="target.valueType.filter"
                            [popoverOnBody]="true"
                            (changed)="updateMultiselectCheckbox($event)"
                        ></s25-ng-multiselect-search-criteria>
                        @if (!!rule.targets[target.valueType.action].length) {
                            <div class="header">
                                {{ target.valueType.checkboxLabel }}
                            </div>
                        }
                        @for (item of rule.targets[target.valueType.action]; track item; let i = $index) {
                            <div class="multiselectRow">
                                <button (click)="rule.targets[target.valueType.action].splice(i, 1)" class="delete">
                                    <s25-ng-icon [type]="'close'" [label]="'Delete'"></s25-ng-icon>
                                </button>
                                <div class="flexRow">
                                    <label>{{ item.itemName }}</label>
                                    <s25-ng-checkbox
                                        [(modelValue)]="item.checkbox"
                                        [noLabel]="true"
                                        (modelValueChange)="item.itemValue = 'checked=' + $event"
                                    />
                                </div>
                            </div>
                        }
                    </div>
                }
                @case ("dropdown") {
                    <div class="flexRow dropdown {{ target.valueType.criterion }}">
                        <s25-ng-dropdown-search-criteria
                            [type]="target.valueType.criterion"
                            [customFilterValue]="
                                rule.conditionFilterMap?.[target.valueType.criterion] ?? target.valueType.filter
                            "
                            [(chosen)]="rule.targets[target.valueType.action][0]"
                        ></s25-ng-dropdown-search-criteria>
                        <button
                            class="aw-button aw-button--outline clearPrimaryOrg"
                            (click)="rule.targets[target.valueType.action] = []"
                        >
                            Clear
                        </button>
                    </div>
                }
                @case ("multiselect") {
                    <s25-ng-multiselect-search-criteria
                        [modelBean]="{ showResult: true }"
                        [selectedItems]="rule.targets[target.valueType.action]"
                        [type]="target.valueType.criterion"
                        [customFilterValue]="
                            rule.conditionFilterMap?.[target.valueType.criterion] ?? target.valueType.filter
                        "
                        [popoverOnBody]="true"
                    ></s25-ng-multiselect-search-criteria>
                }
                @case ("textarea") {
                    <div>
                        <textarea
                            type="text"
                            class="c-input"
                            [(ngModel)]="rule.targets[target.valueType.action][0].itemValue"
                            [maxLength]="512"
                        ></textarea>
                    </div>
                }
                @case ("richText") {
                    <div>
                        <s25-ng-rich-text-editor
                            [(modelValue)]="rule.targets[target.valueType.action][0].itemValue"
                            [resize]="true"
                            [updateOn]="'keyup'"
                        />
                        <p class="richTextHint">
                            Character count includes hidden formatting. <br />See "Source Code" under "View" for full
                            text.
                        </p>
                    </div>
                }
                @case ("yesNo") {
                    <div class="toggle">
                        <s25-toggle-button
                            [falseLabel]="'No'"
                            [trueLabel]="'Yes'"
                            [(modelValue)]="rule.targets[target.valueType.action][0].itemValue"
                        />
                    </div>
                }
                @case ("additionalTime") {
                    <div class="additional-time">
                        <label>Days</label>
                        <label>Hours</label>
                        <label>Minutes</label>
                        @for (type of ["setup", "pre", "post", "takedown"]; track type) {
                            <label class="row-header">{{ type }}</label>
                            @for (unit of ["days", "hours", "minutes"]; track unit) {
                                <input
                                    class="c-input"
                                    type="number"
                                    [attr.aria-label]="unit"
                                    [(ngModel)]="targets[0].minAdditionalTime[type][unit]"
                                    (ngModelChange)="updateTargets()"
                                    [min]="0"
                                />
                            }
                        }
                    </div>
                }
                @case ("setAdditionalTime") {
                    <div class="set-additional-time">
                        <label>Day</label>
                        <label>Time</label>
                        @for (type of ["setup", "pre", "post", "takedown"]; track type) {
                            <label class="row-header">{{ type }}</label>
                            <select
                                [(ngModel)]="targets[0].setAdditionalTime[type].dow"
                                class="cn-form__control"
                                (ngModelChange)="updateTargets()"
                            >
                                @for (dow of weekdays; track dow) {
                                    <option [value]="dow">{{ dow }}</option>
                                }
                            </select>
                            <s25-timepicker
                                [defaultZero]="true"
                                [(modelValue)]="targets[0].setAdditionalTime[type].time"
                                (modelValueChange)="updateTargets()"
                            />
                        }
                    </div>
                }
            }
        }
    `,
    styles: `
        .flexRow,
        .multiselectRow {
            display: flex;
            gap: 0.5em;
        }

        .flexRow > :first-child {
            flex-grow: 1;
        }

        .contactRole,
        .multiselectWithOption {
            display: grid;
            gap: 0.5em;
        }

        .contactRole label {
            font-weight: bold;
        }

        .contactRoleRow {
            position: relative;
            display: grid;
            gap: 0.5em;
        }

        .contactRoleRow .delete {
            position: absolute;
            top: -2px; /* Icon has 2px of blank space */
            left: -2px;
        }

        .multiselectWithOption .header {
            width: 5em;
            margin-left: auto;
        }

        .delete {
            border: 0;
            background: transparent;
            padding: 0;
            cursor: pointer;
        }

        .delete s25-ng-icon:hover {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            padding: 0.25em;
            box-sizing: content-box;
            margin: -0.25em;
        }

        .multiselectRow > div {
            flex-grow: 1;
        }

        .multiselectWithOption label {
            align-self: center;
            text-align: left;
            flex: 1;
        }

        .multiselectWithOption input,
        .multiselectWithOption s25-ng-checkbox {
            width: 5em;
        }

        .dropdown.eventStates {
            min-height: 12rem;
        }

        .dropdown.eventStates button {
            height: calc(2.5em + 2px);
        }

        .toggle {
            display: flex;
            justify-content: center;
        }

        .richTextHint {
            display: block;
            margin-top: 0.5em;
            font-size: 0.8em;
            color: #777;
        }

        ::ng-deep .nm-party--on s25-ng-rule-target .richTextHint {
            color: #ccc;
        }

        textarea {
            width: min(100%, 500px) !important;
            min-height: 9em;
            padding: 0.5em !important;
        }

        ::ng-deep .s25-multiselect-popup-container {
            max-width: 50vw;
        }

        ::ng-deep s25-ng-rule-target s25-generic-dropdown .select2-choice {
            padding-left: 3px !important;
        }

        ::ng-deep s25-ng-rule-target s25-ng-dropdown-search-criteria .s25-item-holder {
            vertical-align: middle;
            padding-right: 0.5em;
        }

        .additional-time,
        .set-additional-time {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            row-gap: 0.25em;
            column-gap: 0.25em;

            & > :first-child {
                grid-column: 2;
            }

            & > * {
                min-width: 0;
            }

            label {
                font-weight: bold;
            }

            .row-header {
                text-transform: capitalize;
                text-align: left;
                align-content: center;
                font-weight: normal;
            }
        }

        .set-additional-time {
            grid-template-columns: repeat(3, 1fr);
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        S25CheckboxModule,
        S25DropdownPaginatedModule,
        S25IconModule,
        S25MultiselectModule,
        S25RichTextEditorModule,
        S25ToggleButtonModule,
        S25TimepickerModule,
    ],
})
export class S25RuleTargetComponent implements OnInit {
    @Input({ required: true }) rule: Rules.Rule;
    @Input({ required: true }) target: Rules.Target;

    init = false;
    weekdays: string[] = [];
    targets: ActionTarget[];

    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        this.initializeTargets();

        const weekStart = await UserprefService.getWeekstart();
        this.weekdays = S25Util.date.getDayNames(weekStart);
        this.weekdays.unshift("None");

        this.init = true;
        this.cd.detectChanges();
    }

    initializeTargets() {
        this.targets = this.rule.targets[this.target.valueType.action]?.slice();
        if (!this.targets?.length) {
            this.targets = [];
            this.rule.targets[this.target.valueType.action] = [];
        }

        if ("parse" in this.target) {
            this.targets = this.target.parse(this.targets);
        }

        if (!this.targets.length && "default" in this.target) {
            this.targets.push(this.target.default());
        }

        this.updateTargets();
    }

    updateQuantity(items: Rules.ActionTarget[]) {
        for (let item of items) {
            item.number ??= 1;
            item.itemValue ??= `quantity=${item.number}`;
        }
    }

    updateMultiselectCheckbox(items: Rules.ActionTarget[]) {
        for (let item of items) {
            item.checkbox ??= true;
            item.itemValue ??= `checked=${item.checkbox}`;
        }
    }

    updateTargets() {
        const action = this.target.valueType.action;
        if ("serialize" in this.target) this.rule.targets[action] = this.target.serialize(this.targets || []);
        this.rule.targets[action] = this.rule.targets[action] || [];
    }
}
