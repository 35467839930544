import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { RuleTreeService } from "../../services/rule.tree.service";
import { SearchCriteriaService } from "../../services/search/search-criteria/search.criteria.service";
import { Item } from "../../pojo/Item";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { CustomAttributeService } from "../../services/custom.attribute.service";
import { S25ItemI } from "../../pojo/S25ItemI";
import { S25RuleConditionsComponent } from "./s25.rule.conditions.component";
import { Rules } from "./s25.rule.const";

@TypeManagerDecorator("s25-ng-rule")
@Component({
    selector: "s25-ng-rule",
    template: `
        @if (isInit) {
            <div class="root-rule">
                <div class="flexRow">
                    <label for="active" class="ngBold">Active: </label>
                    <s25-toggle-button [(modelValue)]="rule.active" id="active" name="active"></s25-toggle-button>
                </div>
                <div class="flexRow">
                    <label for="ruleName" class="ngBold">Name: </label>
                    <input id="ruleName" [(ngModel)]="rule.name" type="text" maxlength="40" class="c-input" />
                </div>
                <div class="flexRow">
                    <label for="ruleDescription" class="ngBold">Description: </label>
                    <textarea id="ruleDescription" [(ngModel)]="rule.description" class="c-input"></textarea>
                </div>
                <s25-ng-rule-conditions
                    [category]="rule.category"
                    [conditions]="rule.conditions"
                    [isTopLevel]="true"
                    [discreteOptions]="discreteOptions"
                    [customAttributes]="customAttributes"
                    [conditionFilterMap]="rule.conditionFilterMap"
                ></s25-ng-rule-conditions>
                <h3 class="targetsLabel">Targets</h3>
                <div class="targets">
                    @for (target of targetOptions; track target) {
                        <s25-simple-collapse [headerText]="getHeaderText(target)" [defaultCollapsed]="true">
                            <s25-ng-rule-target [rule]="rule" [target]="target" />
                        </s25-simple-collapse>
                    }
                </div>
                <div class="buttons-bar">
                    <button class="aw-button aw-button--primary" (click)="save()">Save</button>
                    <button class="aw-button aw-button--outline" (click)="cancel()">Cancel</button>
                </div>
            </div>
        }
    `,
    styles: `
        #ruleDescription {
            width: 100%;
            min-height: 5rem;
        }

        .flexRow {
            display: flex;
            gap: 0.5em;
        }

        .flexRow > :first-child {
            flex-grow: 1;
        }

        .targetsLabel {
            padding-top: 1em;
        }

        .targets {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(min(100%, 20em), 1fr));
            gap: 0.5em;
        }

        s25-ng-rule-target {
            display: block;
            text-align: center;
            padding: 0.5em;
        }

        .buttons-bar {
            display: flex;
            gap: 0.5em;
            padding-top: 1em;
        }

        ::ng-deep s25-ng-rule .targets .simple-collapse--wrapper {
            margin: 0 !important;
        }

        ::ng-deep s25-ng-rule .targets .simple-collapse--wrapper.expanded {
            height: 100%;
        }

        ::ng-deep s25-ng-rule .simple-collapse--wrapper .c-sectionHead h2 {
            padding-right: 2em;
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .root-rule > .flexRow {
            align-items: center;
            padding-bottom: 1em;
            flex-wrap: wrap;
            justify-content: start;
        }

        .root-rule > .flexRow > :first-child {
            width: 6em;
            flex-grow: 0;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25RuleComponent implements OnInit {
    @Input() rule: Rules.Rule;

    @Output() saved = new EventEmitter<void>();
    @Output() canceled = new EventEmitter<void>();

    @ViewChild(S25RuleConditionsComponent) childRule: S25RuleConditionsComponent;

    // Template aliases
    Rules = Rules;

    isInit = false;
    targetOptions: Rules.Target[];
    discreteOptions: Record<number, S25ItemI[]> = {};
    customAttributes: Rules.SourceItem[] = [];

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        this.targetOptions = Rules.targetOptions[this.rule.category];
        await Promise.all([this.getDiscreteOptions(), this.getCustomAttributes()]);
        this.initializeTargets();
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    initializeTargets() {
        // Initialize targets
        const targets = this.rule.targets;
        for (let target of this.targetOptions) {
            if (targets[target.valueType.action]) continue; // Skip if already initialized
            targets[target.valueType.action] = [];
            if (target.valueType.type === "textarea" || target.valueType.type === "richText") {
                targets[target.valueType.action].push({
                    itemValue: "",
                    itemTypeId: target.id,
                    itemId: target.id,
                    itemName: "",
                });
            } else if (target.valueType.type === "yesNo") {
                targets[target.valueType.action] = [
                    {
                        itemValue: "",
                        itemTypeId: target.id,
                        itemId: target.id,
                        itemName: "",
                    },
                ];
            }
        }
        this.rule.targets = targets as typeof this.rule.targets;
    }

    async getDiscreteOptions() {
        const options = await CustomAttributeService.getAllDiscreteOptions();
        for (let option of options)
            this.discreteOptions[option.itemId] = option.opt.map((o: string) => ({ itemId: o, itemName: o }));
    }

    async getCustomAttributes() {
        const customAttributes = await SearchCriteriaService.getCustomAttributeItems(Item.Ids.Event);
        this.customAttributes = customAttributes
            .filter((attribute) => Rules.allowedCustomAttributeTypes.has(attribute.itemTypeId))
            .map((attribute) => ({
                itemId: attribute.itemId,
                attributeType: attribute.itemTypeId,
                itemName: attribute.itemName,
            }));
    }

    async save() {
        if (!this.validate()) return;
        const ok = !!(await RuleTreeService.putParsedRule(this.rule).catch(S25Util.showError));
        if (ok) this.saved.emit();
    }

    cancel() {
        this.canceled.emit();
    }

    validate() {
        let message: string;
        if (!this.rule.name) message = "Please enter a name for this rule tree";
        if (!message) message = this.childRule.validate();
        if (!message) {
            const hasTarget = Object.entries(this.rule.targets).some(([action, items]) => {
                const type = Rules.targetActionToTarget[action as Rules.Action].valueType.type;
                switch (type) {
                    case "textarea":
                    case "richText":
                        return !!items[0].itemValue.length;
                    case "dropdown":
                        return !!items[0];
                    case "contactRole":
                    case "multiselect":
                    case "multiselectWithQuantity":
                    case "multiselectWithCheckbox":
                    case "additionalTime":
                    case "setAdditionalTime":
                        return !!items.length;
                    case "yesNo":
                        return S25Util.toBool(items[0]?.itemValue);
                    default:
                        return false;
                }
            });
            if (!hasTarget) message = "Please configure at least one target";

            if (this.rule.targets.alertUser?.[0]?.itemValue.length > 512) {
                message =
                    "Alert exceeds allowed character count. Please limit the alert to at most 512" +
                    " characters, including formatting.";
            }
        }

        if (message) alert(message);
        return !message;
    }

    getHeaderText(target: Rules.Target) {
        if ("headerText" in target) {
            return `${target.label} ${target.headerText(this.rule.targets[target.valueType.action])}`;
        }

        let text = String(this.rule.targets[target.valueType.action].length);
        if (target.valueType.type === "textarea" || target.valueType.type === "richText") {
            const count = this.rule.targets[target.valueType.action][0]?.itemValue?.length ?? 0;
            text = count ? `${count} / 512` : String(count);
        } else if (target.valueType.type === "yesNo") {
            text = S25Util.toBool(this.rule.targets[target.valueType.action][0]?.itemValue) ? "Yes" : "No";
        } else if (target.valueType.type === "dropdown") {
            text = this.rule.targets[target.valueType.action][0]?.itemName || "None";
        } else if (target.valueType.type === "additionalTime") {
            text = this.rule.targets[target.valueType.action].length ? "Yes" : "No";
        }
        return `${target.label} (${text})`;
    }
}
